import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../Footer';
import './layout.css';
import GlobalStyles from '../../styles';

const Layout = ({ children }) => (
  <>
    {children}
    <Footer />
    <GlobalStyles />
  </>
);


Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
