import styled from 'styled-components';
import { WithUnderline } from './link';
import { color } from './theme';

const Nav = styled.ul`
  margin: 25px 0;
  padding: 0;
  text-align: ${({ align }) => align || 'center'};
  
  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const NavItem = styled.li`
  display: block;
  margin: 0;
  padding: 10px 15px;
  list-style: none;
  
  @media (min-width: 640px) {
    display: inline-block;
    padding-right: 25px;
    padding-left: 25px;
    
    &:first-child {
      padding-left: 0;
    }
  
    &:last-child {
      padding-right: 0;
    }
  }
  
  @media (min-width: 1920px) {
    padding-right: 50px;
    padding-left: 50px;
  }
`;

const NavLink = styled(WithUnderline)`
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.25;
  color: ${color('white')};
  letter-spacing: -0.3px;
  text-decoration: none;
`;

export { Nav, NavItem, NavLink };
