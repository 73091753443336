import React from 'react';
import styled, { css } from 'styled-components';
import { color } from '../../styles/theme';

const Button = styled.button`
  position: relative;
  z-index: 10;
  border: 0;
  padding: 0;
  background: none;
  outline: none;
  cursor: pointer;
  
  ${({ open }) => open && css`
    span {
      transition: top .3s ease, transform .3s ease .3s;
      
      &:nth-child(2) {
        visibility: hidden;
        transition: visibility 0s ease .3s;
      }
      
      &:nth-child(1) {
        top: 10px;
        transform: rotate(45deg);
      }
      
      &:nth-child(3) {
        top: -10px;
        width: 30px;
        transform: rotate(-45deg);
      }
    }
  `};
  
  @media (min-width: 769px) {
    display: none;
  }
`;

const Line = styled.span`
  display: block;
  position: relative;
  top: 0;
  width: 30px;
  height: 2px;
  margin: 8px 0;
  background-color: ${color('white')};
  transition: top .3s ease .3s, transform .3s ease, visibility .3s ease .3s;
  
  &:nth-child(3) {
    margin-left: auto;
    width: 20px;
  }
`;

const MenuButton = ({ active, onClick }) => (
  <Button open={active} onClick={onClick}>
    <Line />
    <Line />
    <Line />
    <span className="visually-hidden">Menu</span>
  </Button>
);

export { MenuButton };
