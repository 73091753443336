import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Nav, NavItem, NavLink } from '../styles/navigation';
import { navigation } from '../settings/settings';
import { themeColor } from '../styles/theme';
import logo from '../images/logo-full.png';
import linkedin from '../images/linkedin-icon.png';

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 45px 15px;
  background-color: ${themeColor('primary')};
  
  @media (min-width: 960px) {
    flex-direction: row;
    justify-content: space-between;
  }
  
  @media (min-width: 1024px) {
    padding-right: 55px;
    padding-left: 70px;
  }
  
  @media (min-width: 1200px) {
    padding-right: 85px;
    padding-left: 140px;
  }
  
  .footer-navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media (min-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
    }
    
    @media (min-width: 960px) {
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: nowrap;
    }
  }
`;

const Logo = styled.img`
  max-width: 155px;
  height: auto;
`;

const SocialLink = styled.a`
  display: inline-block;
  width: 44px;
  height: 44px;
  
  @media (min-width: 768px) {
    margin-top: -4px;
  }
  
  @media (min-width: 960px) {
    margin-left: 70px;
  }
  
  @media (min-width: 1920px) {
    margin-left: 120px;
  }
  
  img {
    border-radius: 50%;
    transition: transform .3s;
  }
  
  &:hover {
    img {
      transform: scale(1.1);
      box-shadow: 0 0 10px rgba(255,255,255, 0.5);
    }
  }
`;

const Footer = () => (
  <FooterWrapper>
    <div className="footer-logo">
      <Link to="/">
        <span className="visually-hidden">New Normal</span>
        <Logo src={logo} alt="" />
      </Link>
    </div>
    <div className="footer-navigation">
      <Nav css={{
        '@media (min-width: 768px)': {
          marginTop: '35px',
          marginBottom: '35px',
        },
        '@media (min-width: 960px)': {
          marginTop: 0,
          marginBottom: 0,
        },
      }}
      >
        {navigation.secondary.map((link) => (
          <NavItem key={link.name}>
            <NavLink to={link.href}>
              {link.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <SocialLink
        href="https://www.linkedin.com/company/new-normal-agency/"
        target="_blank"
        rel="me noopener"
      >
        <span className="visually-hidden">Facebook</span>
        <img src={linkedin} alt="" />
      </SocialLink>
    </div>
  </FooterWrapper>
);

export default Footer;
