import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Nav, NavItem, NavLink } from '../../styles/navigation';
import { MenuButton } from './MenuButton';
import { themeColor } from '../../styles/theme';
import { navigation } from '../../settings/settings';

const setOffsetItem = () => {
  let styles = '';

  for (let i = 1; i < navigation.main.length + 1; i += 1) {
    styles += `
      &:nth-child(${i}) {
        transform: translateX(1${i}5%);
        transition-delay: .${i}s;
      };
    `;
  }

  return styles;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const NavItemStyled = styled(NavItem)`
  transition: all .3s ease;
  
  @media (max-width: 768.98px) {
    display: block;
    padding: 10px 15px;
    will-change: transform;

    ${css`${setOffsetItem()}`};
    
    &:last-child {
      padding-right: 15px;
    }
    
    a {
      font-size: 28px;
    }
  }

  @media (min-width: 1024px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`;

const NavStyled = styled(Nav)`
  position: fixed;
  top: 0;
  right: -100%;
  margin: 0;
  padding: 130px 5px 20px;
  width: 100%;
  height: 100%;
  text-align: right;
  background-color: ${themeColor('primary')};
  opacity: 0;
  transition: all .35s ease;
  
  ${({ active }) => active && css`
    right: 0;
    opacity: 1;
    
    ${NavItemStyled} {
      transform: translateX(0) !important;
    }
  `};
  
  @media (min-width: 769px) {
    display: block;
    position: static;
    padding: 0;
    opacity: 1;
    background-color: transparent;
  }
`;

const MainNav = ({ navLinks = navigation.main }) => {
  const [open, setOpen] = useState(false);

  function resizeWindow() {
    if (window.matchMedia('(min-width: 768px)').matches) {
      document.body.style.overflow = 'scroll';
    }
  }

  function toggleNav() {
    setOpen(!open);

    if (open) {
      document.body.style.overflow = 'scroll';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }

  function closeNav() {
    setOpen(false);
    document.body.style.overflow = 'scroll';
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow);
    return window.addEventListener('resize', resizeWindow);
  });

  return (
    <Wrapper>
      <NavStyled active={open}>
        {navLinks.map((link) => (
          <NavItemStyled key={link.name}>
            <NavLink to={link.href} onClick={closeNav}>
              {link.name}
            </NavLink>
          </NavItemStyled>
        ))}
      </NavStyled>
      <MenuButton active={open} onClick={toggleNav} />
    </Wrapper>
  );
};

export { MainNav };
