/**
 * Site settings
 */
export const navigation = {
  main: [
    {
      name: 'Work',
      href: '/#work',
    },
    {
      name: 'Services',
      href: '/services/',
    },
    // {
    //   name: 'Health IT',
    //   href: '/services/healthit/',
    // },
    {
      name: 'People',
      href: '/#team',
    },
    // {
    //   name: 'Blog',
    //   href: '/blog/',
    // },
    {
      name: 'Contact',
      href: '/#contact',
    },
  ],
  secondary: [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Services',
      href: '/services/',
    },
    {
      name: 'Work',
      href: '/#work',
    },
    {
      name: 'People',
      href: '/#team',
    },
    // {
    //   name: 'Blog',
    //   href: '/blog/',
    // },
    {
      name: 'Contact',
      href: '/#contact',
    },
  ],
};
