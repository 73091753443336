import { createGlobalStyle } from 'styled-components';
import fontFamily, { gray } from './theme';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-weight: normal;
    font-family: GothamPro;
    src: url(/fonts/GothamProRegular.woff) format("woff");
   }

  @font-face {
    font-weight: bold;
    font-family: GothamPro;
    src: url(/fonts/GothamProBold.woff) format("woff");
  }
  
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    font-weight: normal;
    font-size: 16px;
    font-family: ${fontFamily};
    color: ${gray(500)};
  }
  
  .visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    overflow: hidden;
  }
  
  // In Firefox, if the field is required, the last field has a red outline.
  input,
  textarea {
    &:required {
      box-shadow: none;
    }
  }
  
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 40px;
    padding: 0;
    overflow: hidden;
  }
  
  .embed-responsive:before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
  
  .embed-responsive iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export default GlobalStyles;
