const colors = {
  'twilight-blue': '#0c485e',
  'turquoise-blue': '#0bbcd6',
  'turquoise-blue-100': '#0ec7dc',
  'turquoise-blue-200': '#08bdd7',
  dark: '#12162d',
  lemon: '#fbfe56',
  gray100: '#f5f5f5',
  gray200: '#f2f2f2',
  gray300: '#e9e9e9',
  gray400: '#c3c3c3',
  gray500: '#9b9b9b',
  white: '#ffffff',
  black: '#000000',
  black100: '#000203',
};

const themeColors = {
  primary: colors['twilight-blue'],
  accent: colors['turquoise-blue'],
};

const graysMap = {
  100: colors.gray100,
  200: colors.gray200,
  300: colors.gray300,
  400: colors.gray400,
  500: colors.gray500,
};

const fontFamily = 'GothamPro, sans-serif';

// Helpers
export const gray = (number) => graysMap[number];
export const color = (color) => colors[color];
export const themeColor = (color) => themeColors[color];

export default {
  colors,
  graysMap,
  themeColors,
  fontFamily,
};
