import { Link } from 'gatsby';
import styled from 'styled-components';

export const UnderlineStyle = `
  position: relative;
  text-decoration: none;
  
  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: 50%;
    transition: transform .3s ease-in-out;
  }
`;

export const WithUnderline = styled(Link)`
  ${UnderlineStyle}
`;
